import { useMatchBreakpointsContext } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/image'
import { memo } from 'react'
import styled from 'styled-components'
import { play2earnImage, play2earnMobileImage } from './images'
import * as S from './Styled'

const Play2earnBanner = () => {
  const { t } = useTranslation()
  const { isDesktop } = useMatchBreakpointsContext()

  return (
    <S.Wrapper>
      <S.Inner>
        {isDesktop ? (
          <Image src={play2earnImage} alt="Play2earnBanner" width={2291} height={456} placeholder="blur" />
        ) : (
          <Image
            className="mobile"
            src={play2earnMobileImage}
            alt="Play2earnBanner"
            width={401}
            height={80}
            placeholder="blur"
          />
        )}
      </S.Inner>
    </S.Wrapper>
  )
}

export default memo(Play2earnBanner)
