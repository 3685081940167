import farmsImage from './farms.png'
import poolsImage from './pools.png'
import launchpadImage from './launchpad.png'
import nftsImage from './nfts.png'
import play2earnImage from './play2earn.png'
import lotteryImage from './lottery.png'
import IFOImage from './IFO.png'
import IFOMobileImage from './IFOMobile.png'
import competitionImage from './competition.png'
import competitionMobileImage from './competitionMobile.png'
import modImage from './mod-tc-desktop.png'
import modMobileImage from './mod-tc-mobile.png'
import farmsMobileImage from './farmsMobile.png'
import poolsMobileImage from './poolsMobile.png'
import launchpadMobileImage from './launchpadMobile.png'
import nftsMobileImage from './nftsMobile.png'
import play2earnMobileImage from './play2earnMobile.png'
import lotteryMobileImage from './lotteryMobile.png'
import modWhiteLogo from './mod-white-logo.png'
import perpetualImage from './perpetual.png'
import perpetualMobileImage from './perpetualMobile.png'

export {
  farmsImage,
  poolsImage,
  launchpadImage,
  nftsImage,
  play2earnImage,
  lotteryImage,
  IFOImage,
  competitionImage,
  modImage,
  modMobileImage,
  farmsMobileImage,
  poolsMobileImage,
  launchpadMobileImage,
  nftsMobileImage,
  play2earnMobileImage,
  lotteryMobileImage,
  competitionMobileImage,
  IFOMobileImage,
  modWhiteLogo,
  perpetualImage,
  perpetualMobileImage,
}
